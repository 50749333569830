import { Component, Vue, Mixins } from 'vue-property-decorator'
import WeekCodeMethods from '@/components/atoms/WeekCodeMethods.vue'

@Component
export default class LoadClassOptionsApi extends Mixins(WeekCodeMethods) {
  // 授業情報取得
  protected async loadClassOptions(branchId: number, weekCode?: number) {
    const privateClassOptions = await this.getPrivateClassOptions(branchId, weekCode)
    const groupClassOptions = await this.getGroupClassOptions(branchId, weekCode)
    // 個別→集団の順で設定
    return privateClassOptions.concat(groupClassOptions)
  }

  // 個別授業のプルダウン選択肢取得
  protected async getPrivateClassOptions(branchId: number, weekCode?: number): Promise<any[]> {
    const privateClassOptions: any[] = []
    await Vue.prototype.$http.httpWithToken
      .get(`/private_class_settings`, { params: { branchId: branchId, weekCode: weekCode } })
      .then((res: any) => {
        const privateClasses = res.data.map((classSetting: { id: number; weekCode: string; periodNum: number }) => {
          return classSetting
        })
        for (let i = 0; i < privateClasses.length; i++) {
          const privateClass = privateClasses[i]
          for (let j = 0; j < privateClass.periodNum; j++) {
            privateClassOptions.push({
              text: `個別授業-${this.weekCodeToText(privateClass.weekCode)}曜-${j + 1}コマ目`,
              value: `false,${privateClass.id},${j + 1}`, // 集団授業フラグ,id,コマ数
            })
          }
        }
      })
    return privateClassOptions
  }

  // 集団授業のプルダウン選択肢取得
  protected async getGroupClassOptions(branchId: number, weekCode?: number): Promise<any[]> {
    // クラスプルダウンの情報設定(集団)
    let groupClassOptions: any[] = []
    await Vue.prototype.$http.httpWithToken
      .get(`/group_class_settings`, { params: { branchId: branchId, weekCode: weekCode } })
      .then((res: any) => {
        groupClassOptions = res.data.groupClassSettings.map(
          (classSetting: { id: number; gradeName: string; groupClassName: string; weekCodes: string[] }) => {
            return {
              text: `集団授業-${this.weekCodesToTexts(classSetting.weekCodes).join('')}-${classSetting.gradeName}-${
                classSetting.groupClassName
              }`,
              value: `true,${classSetting.id}`, // 集団授業フラグ,id
            }
          }
        )
      })
    return groupClassOptions
  }
}
