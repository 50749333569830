









































import { Component, Vue, Ref, Mixins } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import CheckboxWithLabel from '@/components/molecules/CheckboxWithLabel.vue'
import RangeDatePicker from '@/components/molecules/RangeDatePicker.vue'
import moment from 'moment'
import LocalMoment from '@/components/atoms/LocalMoment.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'
import LoadClassOptionsApi from '@/mixins/teacher/LoadClassOptionsApi'
import VictoryBranch from '@/mixins/v3/VictoryBranch'

@Component({
  components: {
    CheckboxWithLabel,
    RangeDatePicker,
    ButtonBase,
    SelectBase,
  },
})
export default class TimelineSearch extends Mixins(LocalMoment, LoadClassOptionsApi, VictoryBranch) {
  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId

  private isV3 = Vue.prototype.$gdlsCookiesV3.isV3()

  private gradeDatas: object[] = [
    { label: '小1', value: 's1' },
    { label: '小2', value: 's2' },
    { label: '小3', value: 's3' },
    { label: '小4', value: 's4' },
    { label: '小5', value: 's5' },
    { label: '小6', value: 's6' },
    { label: '中1', value: 't1' },
    { label: '中2', value: 't2' },
    { label: '中3', value: 't3' },
  ]

  private learningTypeDatas: object[] = Vue.prototype.$gdlsCookiesV3.isV3()
    ? [
        { label: '通常', value: 'AI' },
        { label: 'カスタム', value: 'CUSTOM' },
        { label: '入試', value: 'NY' },
        { label: '補助', value: 'HJ' },
        { label: '間違', value: 'MN' },
      ]
    : [
        { label: '通常', value: 'TJ' },
        { label: '対策', value: 'TK' },
        { label: '講習', value: 'KS' },
        { label: '入試', value: 'NY' },
        { label: '補助', value: 'HJ' },
        { label: '間違', value: 'MN' },
        { label: 'AI', value: 'AI' },
      ]

  private subjectDatas: object[] = [
    { label: '算数/数学', value: 'su' },
    { label: '英語', value: 'ei' },
    { label: '国語', value: 'ko' },
    { label: '社会', value: 'sh' },
    { label: '理科', value: 'ri' },
  ]

  private learningMaterialDatas: object[] = Vue.prototype.$gdlsCookiesV3.isV3()
    ? [
        { label: '導入動画', value: 'EZ' },
        { label: '理解度テスト', value: 'RK' },
        { label: '演習', value: 'EN' },
        { label: '宿題', value: 'SY' },
        { label: '動画（補助教材）', value: 'HD' },
        { label: '練習（補助教材）', value: 'HR' },
        { label: 'テスト（補助教材）', value: 'HT' },
        { label: '間違い直し', value: 'MN' },
      ]
    : [
        { label: '確認テスト', value: 'KK' },
        { label: '導入動画', value: 'EZ' },
        { label: '理解度テスト', value: 'RK' },
        { label: '演習', value: 'EN' },
        { label: '宿題', value: 'SY' },
        { label: '動画（補助教材）', value: 'HD' },
        { label: '練習（補助教材）', value: 'HR' },
        { label: 'テスト（補助教材）', value: 'HT' },
        { label: '間違い直し', value: 'MN' },
      ]

  private studentId = ''
  private studentName = ''
  private schoolName = ''

  private classOptionDatas: { text: string; value: string | null }[] = []
  private classSelectedData: string | null = null

  private attributeOptionDatas: { text: string; value: string | null }[] = []
  private attributeSelectedData: string | null = null

  @Ref() rangeDatePicker!: RangeDatePicker

  private async mounted() {
    await this.loadOptions()
    // クッキーに検索条件が存在する場合はセットする
    if (this.isV3 && this.isVictoryBranch) {
      this.learningMaterialDatas.push(
        { label: '【C】動画', value: 'VIDEO' },
        { label: '【C】教材', value: 'PR' },
        { label: '【C】漢字', value: 'KJ' }
      )
    }
    const conditions = Vue.prototype.$cookies.get('dataGdls').timelineConditions
    if (conditions) {
      this.restoreConditions(conditions)
    } else {
      this.select()
    }
  }

  private clear() {
    this.rangeDatePicker.startDatePickerBase.selectedDate = this.beforeDays(14).toDate()
    this.rangeDatePicker.endDatePickerBase.selectedDate = null
    this.studentId = ''
    this.studentName = ''
    this.schoolName = ''
    this.gradeDatas.forEach((data: any) => {
      data.checked = false
    })
    this.learningTypeDatas.forEach((data: any) => {
      data.checked = false
    })
    this.subjectDatas.forEach((data: any) => {
      data.checked = false
    })
    this.learningMaterialDatas.forEach((data: any) => {
      data.checked = false
    })
    this.classSelectedData = null
    this.attributeSelectedData = null
  }

  private select() {
    const conditionsDatas = []

    // 期間開始
    const startDate = this.rangeDatePicker.startDatePickerBase.selectedDate
    if (startDate) {
      conditionsDatas.push({
        label: '期間開始',
        name: moment(startDate).format('YYYY/MM/DD'),
        key: 'startedAtGteq',
        value: moment(startDate).startOf('day').format('YYYY/MM/DD HH:mm:ssZ'),
      })
    }

    // 期間終了
    const endDate = this.rangeDatePicker.endDatePickerBase.selectedDate
    if (endDate) {
      conditionsDatas.push({
        label: '期間終了',
        name: moment(endDate).format('YYYY/MM/DD'),
        key: 'startedAtLteq',
        value: moment(endDate).endOf('day').format('YYYY/MM/DD HH:mm:ssZ'),
      })
    }

    // 生徒id
    if (this.studentId.length > 0) {
      conditionsDatas.push({ label: '生徒id', name: this.studentId, key: 'studentCode', value: this.studentId })
    }

    // 生徒氏名
    if (this.studentName.length > 0) {
      conditionsDatas.push({ label: '生徒氏名', name: this.studentName, key: 'studentName', value: this.studentName })
    }

    // 学校名
    if (this.schoolName.length > 0) {
      conditionsDatas.push({ label: '学校名', name: this.schoolName, key: 'schoolNameCont', value: this.schoolName })
    }

    // 学年
    this.addCheckBoxConditionData(conditionsDatas, this.gradeDatas, '学年', 'grades')

    // 学習種別
    this.addCheckBoxConditionData(conditionsDatas, this.learningTypeDatas, '学習種別', 'classModes')

    // 教科
    this.addCheckBoxConditionData(conditionsDatas, this.subjectDatas, '教科', 'subjects')

    // 学習教材
    this.addCheckBoxConditionData(conditionsDatas, this.learningMaterialDatas, '学習教材', 'classCategories')

    // クラス・コマ
    this.addSelectConditionData(
      conditionsDatas,
      this.classSelectedData,
      this.classOptionDatas,
      'クラス・コマ',
      'lesson'
    )

    // グループ
    this.addSelectConditionData(
      conditionsDatas,
      this.attributeSelectedData,
      this.attributeOptionDatas,
      // '属性',
      'グループ',
      'attribute'
    )

    this.$emit('setConditionsDatas', conditionsDatas)
  }

  private addCheckBoxConditionData(conditionsDatas: any, checkBoxDatas: any, label: string, key: string) {
    const selectedDatas = checkBoxDatas.filter((data: any) => data.checked)
    const valueData = selectedDatas.map((data: any) => {
      if (data.value === 'VIDEO') {
        return ['SM', 'BK', 'MT']
      }
      return data.value
    })
    if (selectedDatas.length > 0) {
      conditionsDatas.push({
        label: label,
        name: selectedDatas.map((data: any) => data.label).join('、'),
        key: key,
        value: valueData.flat(),
      })
    }
  }

  private addSelectConditionData(
    conditionsDatas: any,
    selectedValue: any,
    selectOptions: { text: string; value: string | null }[],
    label: string,
    key: string
  ) {
    if (!selectedValue) return
    const selectedOption = selectOptions.find((option: any) => option.value === selectedValue)
    if (!selectedOption) return
    conditionsDatas.push({
      label: label,
      name: selectedOption.text,
      key: key,
      value: selectedValue,
    })
  }

  public removeConditionsData(label: string) {
    if (label === '期間開始') this.rangeDatePicker.startDatePickerBase.selectedDate = null
    if (label === '期間終了') this.rangeDatePicker.endDatePickerBase.selectedDate = null
    if (label === '生徒id') this.studentId = ''
    if (label === '生徒氏名') this.studentName = ''
    if (label === '学校名') this.schoolName = ''
    if (label === '学年') {
      this.gradeDatas.forEach((data: any) => {
        data.checked = false
      })
    }
    if (label === '学習種別') {
      this.learningTypeDatas.forEach((data: any) => {
        data.checked = false
      })
    }
    if (label === '教科') {
      this.subjectDatas.forEach((data: any) => {
        data.checked = false
      })
    }
    if (label === '学習教材') {
      this.learningMaterialDatas.forEach((data: any) => {
        data.checked = false
      })
    }
    if (label === 'クラス・コマ') this.classSelectedData = null

    if (label === 'グループ') this.attributeSelectedData = null
  }

  private async loadOptions() {
    const emptyOption = [{ text: '', value: null }]
    const attributeOptions = await this.getAttributes()
    this.attributeOptionDatas = emptyOption.concat(attributeOptions)
    const classOptions = await this.loadClassOptions(this.branchId)
    this.classOptionDatas = emptyOption.concat(classOptions)
  }

  // グループ情報読み込み
  private async getAttributes(): Promise<any[]> {
    // グループプルダウンの情報設定
    let attributeOptions: any[] = []
    if (this.isV3) {
      await Vue.prototype.$http.httpWithToken
        .get(`/v3/student_groups`, { params: { branchId: this.branchId, withLinked: true } })
        .then((res: any) => {
          attributeOptions = res.data.studentGroups.map((userAttributes: { id: number; title: string }) => {
            return {
              text: userAttributes.title,
              value: userAttributes.id.toString(),
            }
          })
        })
    } else {
      await Vue.prototype.$http.httpWithToken
        .get(`/user_attributes`, { params: { branchId: this.branchId, withLinked: true } })
        .then((res: any) => {
          attributeOptions = res.data.userAttributes.map((userAttributes: { id: number; title: string }) => {
            return {
              text: userAttributes.title,
              value: userAttributes.id.toString(),
            }
          })
        })
    }
    return attributeOptions
  }

  private restoreConditions(conditions: any[]) {
    const start = conditions.find((condition: { label: string }) => condition.label === '期間開始')
    if (start) {
      this.rangeDatePicker.startDatePickerBase.selectedDate = new Date(start.value)
    } else {
      this.rangeDatePicker.startDatePickerBase.selectedDate = null
    }
    const end = conditions.find((condition: { label: string }) => condition.label === '期間終了')
    if (end) {
      this.rangeDatePicker.endDatePickerBase.selectedDate = new Date(end.value)
    }
    const studentId = conditions.find((condition: { label: string }) => condition.label === '生徒id')
    if (studentId) this.studentId = studentId.value
    const studentName = conditions.find((condition: { label: string }) => condition.label === '生徒氏名')
    if (studentName) this.studentName = studentName.value
    const schoolName = conditions.find((condition: { label: string }) => condition.label === '学校名')
    if (schoolName) this.schoolName = schoolName.value
    const grade = conditions.find((condition: { label: string }) => condition.label === '学年')
    if (grade) {
      this.gradeDatas.forEach((data: any) => {
        data.checked = grade.value.includes(data.value)
      })
    }

    const attribute = conditions.find((condition: { label: string }) => condition.label === 'グループ')

    if (attribute) this.attributeSelectedData = attribute.value
    const lerningType = conditions.find((condition: { label: string }) => condition.label === '学習種別')
    if (lerningType) {
      this.learningTypeDatas.forEach((data: any) => {
        data.checked = lerningType.value.includes(data.value)
      })
    }
    const subject = conditions.find((condition: { label: string }) => condition.label === '教科')
    if (subject) {
      this.subjectDatas.forEach((data: any) => {
        data.checked = subject.value.includes(data.value)
      })
    }
    const learningMaterial = conditions.find((condition: { label: string }) => condition.label === '学習教材')
    if (learningMaterial) {
      this.learningMaterialDatas.forEach((data: any) => {
        data.checked = learningMaterial.value.includes(data.value)
      })
    }
    const period = conditions.find((condition: { label: string }) => condition.label === 'クラス・コマ')
    if (period) this.classSelectedData = period.value
    this.$emit('setConditionsDatas', conditions)
  }
}
